import React from "react"
import Layout from "../components/layout"

import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import UserManagementImage from "../assets/landing/linkfive_user-management.png";
import UserManagement1Image from "../assets/user_management/user_management-1.png";
import UserManagement2Image from "../assets/user_management/user_management-2.png";
import UserManagement3Image from "../assets/user_management/user_management-3.png";
import UserManagement4Image from "../assets/user_management/user_management-4.png";

export default function UserManagement() {
  return (
    <>
      <GatsbySeo title="Complete Subscription User Management"
                 description={`Managing and viewing your users in-app purchase status should be easy and powerful. A single overview with all purchases and all users.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="product">
        <div className="pt-24 bg-l6-background ">
          <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mt-12">
            <div
              className="lg:mb-24 mb-12 md:pl-14 md:pr-14 mb-10 md:mb-0 md:pt-16 lg:w-4/6 w-full flex flex-col items-start">
              <h1
                className="font-extrabold leading-10 tracking-tight sm:leading-none md:w-4/5 md:text-6xl sm:text-5xl text-4xl">
                Complete Subscription User Management
              </h1>

              <p className="my-5 text-lg md:w-4/5 md:text-xl md:mx-0 ">
                Managing and viewing your users in-app purchase status should be easy and powerful. A single overview
                with all purchases and all users.
              </p>
              <ul className="ml-6 list-disc  md:text-lg text-md">
                <li>Cross-Platform User Management</li>
                <li>Manually grant certain users a premium status
                </li>
                <li>Pure user insights about purchases and</li>
              </ul>
            </div>

            <div className="w-full md:w-2/6 py-6 lg:ml-auto mx-auto lg:block hidden">

              <img alt="LinkFive User Management & Purchases Image"
                   title="LinkFive User Management & Purchases Image"
                   className=""
                   src={UserManagementImage}/>
            </div>
          </div>
        </div>

        <section className="text-gray-600 body-font max-w-6xl mx-auto">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">It all starts with the
                user</h2>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">The heart of any subscription management system is its user management.</p>
            </div>

            <div className="px-5 py-12 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement1Image}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">Unique User Ids</h3>
                  <p className="leading-relaxed text-lg text-base">When the app is started and LinkFive is initialized, each user receives a unique user ID.
                    With this user id we can identify each user individually. This happens on all platforms, whether iOS Android, Web or Windows and macOS.</p>
                  <p className="leading-relaxed text-lg text-base mt-4">If you have your own user IDs, we link yours to ours and can thus associate all user sessions with your user.</p>
                </div>
              </div>
            </div>

            <div className="px-5 py-24 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:hidden block">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement2Image}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">We connect all user sessions to your user ID</h3>
                  <p className="leading-relaxed text-lg text-base">
                    Whether your user is on Android, iOS or Web, we link all sessions to your own User ID and give you full control.
                  </p>
                  <p className="leading-relaxed text-lg text-base mt-4">And if one of these sessions makes a purchase, all user sessions will receive the same premium status.</p>
                </div>
              </div>
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:block hidden ">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement2Image}/>
              </div>
            </div>

            <div className="px-5 py-24 mx-auto flex flex-wrap">
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement3Image}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">We know the purchase status</h3>
                  <p className="leading-relaxed text-lg text-base">Through the Apple App Store and Google Play Store server notifications, we always receive the latest purchase update, and when a user cancels a subscription, we receive this information and update the user's status.</p>
                  <p className="leading-relaxed text-lg text-base mt-4">Our SDK constantly receives the latest purchase information and displays it in your app, so we are sure that your user has a valid subscription.</p>
                </div>
              </div>
            </div>

            <div className="px-5 pt-24 mx-auto flex flex-wrap">

              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden  lg:hidden block">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement4Image}/>
              </div>
              <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-10 lg:items-start items-center">
                  <h3 className="text-gray-900 text-2xl title-font font-medium mb-3">Server to Server API</h3>
                  <p className="leading-relaxed text-lg text-base">You can query the premium status of all your users with your own server through our LinkFive User Management API.</p>
                  <p className="leading-relaxed text-lg text-base mt-4">The API will return the time of purchase, payment status, subscription expiration date and many other data.</p>
                </div>
              </div>
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden  lg:block hidden">
                <img alt="feature" className="object-cover object-center h-full w-full"
                     src={UserManagement4Image}/>
              </div>
            </div>

          </div>
        </section>


        <section className="text-gray-600 body-font max-w-6xl mx-auto">
          <div className="container px-5 py-24 mx-auto w-full">
            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">See the user management in action</h2>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">We use a Flutter sample application to show how we do user management</p>
            </div>
            <iframe className="md:w-2/3 mx-auto w-full h-80" src="https://www.youtube.com/embed/F7G94HEh5bQ"
                    title="LinkFive User Management explained on Youtube player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </div>
        </section>
      </Layout>
    </>
  )
}
